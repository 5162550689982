import React from 'react'
import tw, { styled } from 'twin.macro'
import parse from 'html-react-parser'
import { AnimatePresence, motion } from 'framer-motion'
import Chevron from '@elements/Chevron'
import listArrow from '@images/icons/arrow-right.svg'

const Section = tw.section`relative`
const Wrap = tw.div`max-w-screen-md mx-auto`

const WpAcfAccordionBlock = ({ data }) => {
	const { anchor } = data.attributes
	const module = data.modulesAccordion

	return (
		module.items?.length > 0 && (
			<Section {...(anchor !== '' ? { id: anchor } : {})} className='emf-pb-spacing'>
				<Wrap>
					{module.items.map((item, index) => {
						return <AccordionItem key={`accordion-${index}`} item={item} index={index} />
					})}
				</Wrap>
			</Section>
		)
	)
}

const AccordionBox = tw(motion.div)`relative overflow-hidden mx-offset-xs xl:px-5 xl:mx-0 text-base sm:text-lg xl:text-copyXl border-b border-b-light transition-all duration-300`
const AccordionTitleWrap = tw(motion.div)`py-3 sm:py-5 relative flex items-center cursor-pointer w-full justify-between`
const AccordionTitle = tw(motion.p)`relative pr-5`
const AccordionDescription = styled(motion.div)`
	${tw`relative font-futuraLight`}

	a {
		${tw`relative font-futuraBold text-primary hover:text-black`}
	}
	ul {
		br {
			${tw`hidden`}
		}
		li {
			${tw`relative pl-6`}

			&::before {
				content: '';
				${tw`absolute left-0 w-3 h-3 bg-no-repeat bg-contain top-2`}
				background-image: url(${listArrow});
			}
		}
	}
`

const AccordionItem = ({ item, index }) => {
	const [expanded, setExpanded] = React.useState(false)
	const isOpen = index === expanded
	return (
		<AccordionBox
			whileHover={{
				borderBottomColor: 'blue',
			}}
		>
			<AccordionTitleWrap initial={false} onClick={() => setExpanded(isOpen ? false : index)}>
				{item.title && <AccordionTitle>{item.title}</AccordionTitle>}
				<Chevron state={isOpen ? 'up' : 'down'} fill='#666666' />
			</AccordionTitleWrap>
			<AnimatePresence initial={false}>
				{isOpen && item.content && (
					<AccordionDescription initial='init' animate='open' exit='init' variants={accordionVariants}>
						{parse(item.content)}
						<br />
						<br />
					</AccordionDescription>
				)}
			</AnimatePresence>
		</AccordionBox>
	)
}

const transition = {
	duration: 0.8,
	ease: [0.04, 0.62, 0.23, 0.98],
}
const accordionVariants = {
	open: { opacity: 1, height: 'auto', transition: transition },
	init: { opacity: 0, height: 0, transition: transition },
}

export default WpAcfAccordionBlock
